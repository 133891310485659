import InvoiceDataTable from "../../components/DataTable/invoice/InvoiceDataTable";

const Payments = () => {
  return (
    <>
      <div className="datatable-crud">
        <div className="card">
          <InvoiceDataTable></InvoiceDataTable>
        </div>
      </div>
    </>
  );
};

export default Payments;
