export const menuItems = [
  {
    label: "Customer Management",
    items: [
      {
        label: "Customers",
        icon: "pi pi-fw pi-users",
        to: "/customer",
        roles: "ADMIN, MANAGER",
      },
      {
        label: "Payments",
        icon: "pi pi-fw pi-wallet",
        to: "/payments",
        roles: "ADMIN, MANAGER",
      },
      {
        label: "Invoices",
        icon: "pi pi-fw pi-print",
        to: "/invoices",
        roles: "ADMIN, MANAGER",
      },
      {
        label: "Products",
        icon: "pi pi-fw pi-box",
        to: "/product",
        roles: "ADMIN, MANAGER, OPERATOR",
      },
      {
        label: "Subscriptions",
        icon: "pi pi-fw pi-list",
        to: "/subscriptions",
        roles: "ADMIN, MANAGER",
      },
      {
        label: "Messages",
        icon: "pi pi-fw pi-box",
        to: "/messages",
        roles: "ADMIN, MANAGER",
      },
    ],
    icon: "pi pi-fw pi-list",
    roles: "ADMIN, MANAGER",
  },
  {
    label: "Content Management",
    icon: "pi pi-fw pi-video",
    items: [
      {
        label: "Video Content",
        icon: "pi pi-fw pi-video",
        to: "/videocontent",
        roles: "ADMIN, MANAGER, OPERATOR",
      },
      {
        label: "License Management",
        icon: "pi pi-fw pi-key",
        to: "/license",
        roles: "ADMIN, MANAGER",
      },
      {
        label: "Publishing",
        icon: "pi pi-fw pi-upload",
        to: "/publishing",
        roles: "ADMIN, MANAGER, OPERATOR",
      },
      {
        label: "Main Board Items",
        icon: "pi pi-fw pi-th-large",
        to: "/mbi",
        roles: "ADMIN, MANAGER, OPERATOR",
      },
      {
        label: "Label Management",
        icon: "pi pi-fw pi-tags",
        to: "/label",
        roles: "ADMIN, MANAGER, OPERATOR",
      },
      {
        label: "Category Management",
        icon: "pi pi-fw pi-sitemap",
        to: "/category",
        roles: "ADMIN, MANAGER, OPERATOR",
      },
    ],
    roles: "ADMIN, MANAGER, OPERATOR",
  },
  {
    label: "System",
    items: [
      {
        label: "Users",
        icon: "pi pi-fw pi-users",
        to: "/users",
        roles: "ADMIN, MANAGER",
      },
    ],
    icon: "pi pi-fw pi-video",
    roles: "ADMIN",
  },
];

/*export const menuItems = [
  {
    label: "Home",
    items: [
      { label: "Dashboard", icon: "pi pi-fw pi-home", to: "/" },
      { label: "Customers", icon: "pi pi-fw pi-users", to: "/customer" },
      { label: "Payments", icon: "pi pi-fw pi-box", to: "/payments" },
      { label: "Products", icon: "pi pi-fw pi-box", to: "/product" },
      { label: "License Management", icon: "pi pi-fw pi-box", to: "/license" },
      {
        label: "Video Contents",
        icon: "pi pi-fw pi-box",
        to: "/videocontent",
      },

      {
        label: "System",
        icon: "pi pi-fw pi-cog",
        items: [
          {
            label: "Users",
            icon: "pi pi-fw pi-users",
            to: "/users",
          },
        ],
      },
    ],
  },
];*/
